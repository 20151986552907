const servicesData = [
    {
      number: '01',
      title: ' 	Architectural Design',
      logoSrc: './images/icons/arch-icon.png',
      description: `At Sarchi Group of Architects, we focus on architectural design, offering customized services 
      to transform your desires into reality. Our team specializes in creating living spaces that reflect a premium
       and classy lifestyle. We provide expert advice on layout, building, and technical aspects such as lighting, 
       sound, and temperature control.`,
    
    },
    {
        number: '02',
        title: ' 	Interior Design',
        logoSrc: './images/icons/int-icon.png', 
        description: `At Sarchi Group of Architects, we excel in interior design, crafting spaces that 
        embody your personality and enhance functionality and beauty. Our focus is on creating a space 
        that reflects your lifestyle and aspirations, making your house feel like a luxurious home.`,
       
      },
      {
        number: '03',
        title: 'Landscape Design',
        logoSrc: './images/icons/land-icon.png', 
        description: `At Sarchi Group of Architects, we specialize in landscape design, aiming to transform 
        outdoor spaces into stunning and functional areas. Our team combines expertise and creativity to enhance
         the beauty and functionality of your landscape, making it a seamless extension of your home.`,
   
      },
      {
        number: '04',
        title: 'Turnkey Project',
        logoSrc: './images/icons/turnkey-icon.png', 
        description: `At Sarchi Group of Architects, we offer turnkey project solutions, handling everything from 
        initial planning to the final execution. Whether it's new construction or remodeling existing structures, 
        our team ensures a seamless and hassle-free experience, focusing on every detail to bring your vision to 
        life.`,
      },
      

      
      
      
      
      
      

   
  ];
  
  export default servicesData;
  