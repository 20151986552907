
import React from 'react';
// import StickyEnquiryButton from '../../components/StickyEnquiryButton/StickyEnquiryButton';
import StickyIcons from '../../components/StickyIcons/StickyIcons';
import Servicescomponent from '../../components/Services/Servicescomponent/Servicescomponent';
// import HorizontalLine from '../../pages/Services/HorizontalLine';
import ScrollToTopButton from '../../components/ScrollToTopButton/ScrollToTopButton';
const Services = () => {
  return (
    <div>
    
    {/* <HorizontalLine/> */}
   
    <StickyIcons />
      {/* <StickyEnquiryButton /> */}
    <Servicescomponent/>
    <ScrollToTopButton /> s

    </div>
  );
};

export default Services;



