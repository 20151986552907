import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import './Thankyou.css';

const Thankyou = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const goToHomepage = () => {
    navigate('/home'); // Navigate to the "/home" route
  };

  return (
    <div className="thank-you-page">
      <h2>Thank you for reaching out to us.</h2>
      <p>We shall get back to you shortly.</p>
      <button onClick={goToHomepage}>Go To Homepage</button>
    </div>
  );
};

export default Thankyou;
