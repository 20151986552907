const projectsData =[
    { 
      "id": 1, 
      "title": "Project 1", 
      "imageUrl": "/images/Interior/Chavan/1.png",
      "images": [
        { "src": "/images/Interior/Chavan/1.png", "alt": "Interior Project 1 Image 1" },
        { "src": "/images/Interior/Chavan/2.png", "alt": "Interior Project 1 Image 2" },
        { "src": "/images/Interior/Chavan/3.png", "alt": "Interior Project 1 Image 3" },
        { "src": "/images/Interior/Chavan/4.png", "alt": "Interior Project 1 Image 4" },
        { "src": "/images/Interior/Chavan/5.png", "alt": "Interior Project 1 Image 5" },
        { "src": "/images/Interior/Chavan/6.png", "alt": "Interior Project 1 Image 6" },
        { "src": "/images/Interior/Chavan/7.png", "alt": "Interior Project 1 Image 7" },
        { "src": "/images/Interior/Chavan/8.png", "alt": "Interior Project 1 Image 8" },
        { "src": "/images/Interior/Chavan/9.png", "alt": "Interior Project 1 Image 9" },
        { "src": "/images/Interior/Chavan/10.png", "alt": "Interior Project 1 Image 10" }
      ]
    },
    { 
      "id": 2, 
      "title": "Project 2", 
      "imageUrl": "/images/Interior/GAWADE/1.png",
      "images": [
        { "src": "/images/Interior/GAWADE/1.png", "alt": "Interior Project 2 Image 1" },
        { "src": "/images/Interior/GAWADE/2.png", "alt": "Interior Project 2 Image 2" },
        { "src": "/images/Interior/GAWADE/3.png", "alt": "Interior Project 2 Image 3" },
        { "src": "/images/Interior/GAWADE/4.png", "alt": "Interior Project 2 Image 4" },
        { "src": "/images/Interior/GAWADE/5.png", "alt": "Interior Project 2 Image 5" },
        { "src": "/images/Interior/GAWADE/6.png", "alt": "Interior Project 2 Image 6" },
        { "src": "/images/Interior/GAWADE/7.png", "alt": "Interior Project 2 Image 7" },
        { "src": "/images/Interior/GAWADE/8.png", "alt": "Interior Project 2 Image 8" },
        { "src": "/images/Interior/GAWADE/9.png", "alt": "Interior Project 2 Image 9" },
        { "src": "/images/Interior/GAWADE/10.png", "alt": "Interior Project 2 Image 10" },
        { "src": "/images/Interior/GAWADE/11.png", "alt": "Interior Project 2 Image 11" },
        { "src": "/images/Interior/GAWADE/12.png", "alt": "Interior Project 2 Image 12" },
        { "src": "/images/Interior/GAWADE/13.png", "alt": "Interior Project 2 Image 13" },
        { "src": "/images/Interior/GAWADE/14.png", "alt": "Interior Project 2 Image 14" }
      ]
    },
    { 
      "id": 3, 
      "title": "Project 3", 
      "imageUrl": "/images/Interior/Mane/1.png",
      "images": [
        { "src": "/images/Interior/Mane/1.png", "alt": "Interior Project 3 Image 1" },
        { "src": "/images/Interior/Mane/2.png", "alt": "Interior Project 3 Image 2" },
        { "src": "/images/Interior/Mane/3.png", "alt": "Interior Project 3 Image 3" },
        { "src": "/images/Interior/Mane/4.png", "alt": "Interior Project 3 Image 4" },
        { "src": "/images/Interior/Mane/5.png", "alt": "Interior Project 3 Image 5" },
        { "src": "/images/Interior/Mane/6.png", "alt": "Interior Project 3 Image 6" },
        { "src": "/images/Interior/Mane/7.png", "alt": "Interior Project 3 Image 7" },
        { "src": "/images/Interior/Mane/8.png", "alt": "Interior Project 3 Image 8" },
        { "src": "/images/Interior/Mane/9.png", "alt": "Interior Project 3 Image 9" },
        { "src": "/images/Interior/Mane/10.png", "alt": "Interior Project 3 Image 10" }
      ]
    },
    { 
      "id": 4, 
      "title": "Project 4", 
      "imageUrl": "/images/Interior/Tambe/1.png",
      "images": [
        { "src": "/images/Interior/Tambe/1.png", "alt": "Interior Project 4 Image 1" },
        { "src": "/images/Interior/Tambe/2.png", "alt": "Interior Project 4 Image 2" },
        { "src": "/images/Interior/Tambe/3.png", "alt": "Interior Project 4 Image 3" },
        { "src": "/images/Interior/Tambe/4.png", "alt": "Interior Project 4 Image 4" },
        { "src": "/images/Interior/Tambe/5.png", "alt": "Interior Project 4 Image 5" },
        { "src": "/images/Interior/Tambe/6.png", "alt": "Interior Project 4 Image 6" },
        { "src": "/images/Interior/Tambe/7.png", "alt": "Interior Project 4 Image 7" }
      ]
    }

      ];
      export default projectsData;
   