import React from "react";
import "./Footer.css";
import sarchilogo from "./sarchi-log.png";
const Footer = () => {
  return (
    <div id="footer">
      <div className="footer-container  ">
        <div className="column">
          <img src={sarchilogo} alt="Logo" className="logo" />
          {/* <hr className="divider-footer" /> */}
          {/* <p className='Address-para'>
            Pune Office : Flat No. 402, Mayaca Enclave, Punawale, Pimpri-Chinchwad, Maharashtra 411033.
          </p> */}
          <p className="Address-para">
            Baramati Office : Rajashri shahu Maharaj complex, Gunawdi chowk,
            Baramati, Maharashtra 413102.
          </p>
        </div>
        <div className="column">
          <hr className="divider-footer" />
          <h3 className="quick-link-title quick-title">Quick Links</h3>
          <hr className="divider-footer" />
          <div className="quick-links">
            <div className="sub-column">
              {/* <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/Services">Services</a></li>
                <li><a href="/privacy">Privacy</a></li>
                <li><a href="/career">Career</a></li>
              </ul>
            </div>
            <div className="sub-column">
              <ul>
              <li><a href="/career">About us</a></li>
                <li><a href="/ourwork">Our Work</a></li>
                <li><a href="/contact">Contact Us</a></li>
                <li><a href="/disclaimer">Disclaimer</a></li>
              </ul> */}
            </div>
          </div>
          <div className="right" id="footer-icons">
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook"></i>
            </a>
            {/* <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a> */}
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-youtube"></i>
            </a>
            <a
              href="https://api.whatsapp.com/send/?phone=%2B917499749762&text&type=phone_number&app_absent=0"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
        </div>
        <div className="column">
          <hr className="divider-footer" />
          <h3>Contact Us</h3>
          <hr className="divider-footer" />

          {/* <p className='Address-para'>
        Pune Office: Wakad, Pune.
    </p>
    <p className='Address-para'>
        Baramati Office: Wakad, Pune.
    </p> */}

          {/* <hr className="divider" /> */}

          <p className="Address-para">
            Phone:
            <a href="tel:+917499749762">+91 74997 49762</a>,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {/* <a href="tel:+918999926783">+91 89999 26783</a> */}
          </p>

          {/* <hr className="divider" /> */}

          {/* <hr className="divider" /> */}

          <p className="Address-para">
            Email:
            <a href="mailto:sarchigarchitects@gmail.com">
              sarchigarchitects@gmail.com
            </a>
          </p>

          {/* <hr className="divider" /> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
