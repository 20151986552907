import React, { useState } from 'react';
import './ArchitecturalDesignPage.css';
import ProjectModal from './ProjectModal'; // Import the ProjectModal component
import projectsData from './projectsData'; // Import the projectsData array
import { useNavigate } from 'react-router-dom';

const ArchitecturalDesignPage = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleClick = (projectId) => {
    setSelectedProject(projectId);
  };

  const handleCloseModal = () => {
    setSelectedProject(null);
  };

  // Function to navigate to the interior design page
  const navigateToInteriorDesign = () => {
    navigate('/services/interior-design');
  };

  return (
    <div className="architectural-design-page">
      <div className="sidebar">
        <ul>
          {/* Wrap the link inside the <li> element */}
          <li onClick={navigateToInteriorDesign}><a href="/services/interior-design">Interior Design</a></li>
          <li className="active">Architectural Design</li>
        </ul>
      </div>
      <div className={`content ${selectedProject ? 'blur' : ''}`}>
        <div className="projects-grid">
          {Object.keys(projectsData.architectural_design).map((projectId, index) => (
            <div className="project" key={index} onClick={() => handleClick(projectId)}>
              <img src={projectsData.architectural_design[projectId][0].src} alt={projectsData.architectural_design[projectId][0].alt} />
              <div className="project-overlay">
                <p>Click to see Project {index + 1} images</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedProject && (
        <ProjectModal 
          images={projectsData.architectural_design[selectedProject]}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default ArchitecturalDesignPage;
