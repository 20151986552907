// Whatwedo.js

import React from 'react';
import PropTypes from 'prop-types';
import './Whatwedo.css';


const WhatWeDo = () => {
  return (
    <div className="whatwedo-container">
      <h2 className="title">Our Core Values</h2>

      <div className="boxes-container">
        <Boxes
          title="1.	Innovation"
         
          description="We strive to push the boundaries of design, embracing innovative technologies and methodologies to create spaces that are ahead of the curve."
        />

        <Boxes
          title="2.	Sustainability"
         
          description=" Committed to environmentally conscious practices, we integrate sustainable design principles into every project, minimizing our ecological footprint."
        />

<Boxes
          title="3.	Client-Centric Approach"
          
          description="Our clients are at the heart of our designs. We prioritize effective communication, collaboration, and understanding to bring their dreams to life."
        />

        <Boxes
          title="4. Quality Craftsmanship"
          
          description="  Meticulous attention to detail and a dedication to quality craftsmanship ensure that our designs not only meet but exceed industry standards."
        />

        

        

        
      </div>
    </div>
  );
};

const Boxes = ({ title, miniTitle, description }) => {
    return (
      <div className="boxes">
        <div className='whatwe-do-title'>{title}</div>
        <p className="mini-title">{miniTitle}</p>
        <div className="line"></div>
        <p>{description}</p>
      </div>
    );
  };
  // Add PropTypes validation for the Boxes component
Boxes.propTypes = {
    title: PropTypes.string.isRequired,
    miniTitle: PropTypes.string, // If miniTitle is optional, remove isRequired
    description: PropTypes.string.isRequired,
  };

export default WhatWeDo;