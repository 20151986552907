import React, { useState } from "react";
import "./ContactForm.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNo: "",
    role: "",
    interest: "",
    cvUpload: null,
  });
  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // Check if the file is selected
    if (file) {
      // Get the file extension
      const extension = file.name.split(".").pop().toLowerCase();
      // Check if the file extension is PDF
      if (extension === "pdf") {
        setFormData({
          ...formData,
          cvUpload: file, // Store the file object directly
        });
        // Clear any existing errors
        setErrors({
          ...errors,
          cvUpload: "",
        });
      } else {
        // Reset the file input and show an error message
        e.target.value = null;
        setErrors({
          ...errors,
          cvUpload: "Only PDF files are allowed",
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    // Validation
    if (!formData.firstName.trim()) {
      newErrors.firstName = "First name is required";
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last name is required";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }
    if (!formData.contactNo.trim()) {
      newErrors.contactNo = "Contact number is required";
    } else if (!/^\d{10}$/.test(formData.contactNo)) {
      newErrors.contactNo = "Contact number must be 10 digits";
    }
    if (!formData.role) {
      newErrors.role = "Role selection is required";
    }
    if (!formData.interest.trim()) {
      newErrors.interest = "Interest field is required";
    }
    if (formData.cvUpload && formData.cvUpload.size > 104857688888888) {
      newErrors.cvUpload = "CV size exceeds 100 MB limit";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const formDataToSend = new FormData(); // Create a FormData object
      // Append form data to FormData object
      for (let key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      const response = await fetch(
        "https://sarchi-backend.vercel.app/api/submit-career-form",
        {
          // Change the URL to match your backend
          method: "POST",
          body: formDataToSend, // Send FormData instead of JSON string
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form");
      }

      // Form submitted successfully
      setFormSubmitted(true);
      setTimeout(() => {
        // Redirect to thank-you page after 3 seconds
        window.location.href = "/thank-you";
      }, 3000);
      // Mock API call to submit form data
      console.log("Form submitted:", formData);
    } catch (error) {
      console.error("Error submitting form:", error.message);
      // Handle error
    }
  };

  return (
    <div className="careerpage">
      <div className="career-section" id="join-section">
        <div className="joining-heading">Joining The Sarchi&apos;s Team</div>
        <div className="joining-para">
          Sarchi Group of Architect, is Architecture and Interior designing
          firm, based in Pune, has its foundation in people with a passion for
          creating something unique. We are always looking for talented people
          and would love to hear from you. If you are interested in joining our
          team, write to us at{" "}
          <a href="mailto:sarchiarchitects@gmail.com ?subject=Regarding%20Your%20Website&body=Hello,">
            sarchiarchitects@gmail.com{" "}
          </a>{" "}
          to submit your resume and portfolio.
        </div>
      </div>

      <div className="contact-container">
        <div className="sections" id="your-story-section">
          <h2>Submit your interest</h2>
        </div>

        <div className="section" id="form-section">
          {!formSubmitted ? (
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group">
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name:"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    required
                  />
                  {errors.firstName && (
                    <div className="error">{errors.firstName}</div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name:"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    required
                  />
                  {errors.lastName && (
                    <div className="error">{errors.lastName}</div>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email:"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                  {errors.email && <div className="error">{errors.email}</div>}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="contactNo"
                    name="contactNo"
                    placeholder="Contact No:"
                    value={formData.contactNo}
                    onChange={handleInputChange}
                    required
                    pattern="[0-9]{10}"
                  />
                  {errors.contactNo && (
                    <div className="error">{errors.contactNo}</div>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <select
                    id="role"
                    name="role"
                    value={formData.role}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Role</option>
                    <option value="full-time">Full-Time Role</option>
                    <option value="internship">Internship</option>
                  </select>
                  {errors.role && <div className="error">{errors.role}</div>}
                </div>
                <div className="form-group new-class-name">
                  <span className="upload-description">Upload your CV:</span>
                  <input
                    type="file"
                    id="cvUpload"
                    name="cvUpload"
                    accept=".pdf"
                    onChange={handleFileChange}
                    required
                  />
                  {errors.cvUpload && (
                    <div className="error">{errors.cvUpload}</div>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-group full-width">
                  <textarea
                    id="interest"
                    name="interest"
                    placeholder="I am interested in:"
                    rows="4"
                    value={formData.interest}
                    onChange={handleInputChange}
                    required
                  />
                  {errors.interest && (
                    <div className="error">{errors.interest}</div>
                  )}
                </div>
              </div>

              <div className="form-row">
                <button
                  type="submit"
                  className="custom-primary-buttonsend  contact-form-button"
                >
                  Send
                </button>
              </div>
            </form>
          ) : (
            <div className="thank-you-message">
              <i className="fa fa-check-circle"></i>
              <p>Thank you for contacting us!</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
