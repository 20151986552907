import React, { useState } from 'react';
import EnquiryForm from './EnquiryForm/EnquiryForm';
const StickyEnquiryButton = () => {
  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <div className="sticky-enquiry-button-container">
      <button
        className="enquiry-button custom-enquiry-button"
        onClick={toggleForm}
        style={{
          position: 'fixed',
          top: '52%',
          transform: 'translateY(-50%) rotate(90deg)',
          
          right: 0,
          padding: '3px',
          marginRight: '-33px',
          zIndex: 1000,
          fontFamily: 'Acta Display W01 Medium',
          margin: '-10px',
          fontSize: '25px',
          background:'#5d2303',

          border: 'none',
          color: 'white',
          borderRadius: '7px',
        }}
      >
        Enquiry
      </button>
      {showForm && <EnquiryForm />}
    </div>
  );
};

export default StickyEnquiryButton;
