import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './ProjectModal.css'; // Import CSS file for styling the modal
import { FaChevronLeft, FaChevronRight, FaTimes } from 'react-icons/fa'; // Import icons for prev, next, and close buttons

const ProjectModal = ({ images, onClose }) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handlePrev = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNext = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? prevIndex : prevIndex + 1));
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft') {
        handlePrev(e);
      } else if (e.key === 'ArrowRight') {
        handleNext(e);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentIndex, handlePrev, handleNext]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div className="project-modal-overlay">
      <div className="project-modal">
        {currentIndex !== 0 && (
          <button className="prev-button" onClick={handlePrev}><FaChevronLeft /></button>
        )}
        <div className="carousel">
          <img src={images[currentIndex].src} alt={images[currentIndex].alt} />
        </div>
        {currentIndex !== images.length - 1 && (
          <button className="next-button" onClick={handleNext}><FaChevronRight /></button>
        )}
        <button className="close-button" onClick={onClose}><FaTimes /></button>
      </div>
    </div>
  );
};

ProjectModal.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ProjectModal;
