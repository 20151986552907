import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import './Recedential.css';

const Recedential = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleKnowMoreClick = () => {
    navigate('/services/architectural-design'); // Navigate to the specified URL
  };

  return (
    <div className="recedential-container">
      <div className="recedentialleft-section">
        <h2>Architectural Design Projects</h2>
        <div className="custom-divider"></div>
        {/* <p>“I saw the angel in the marble and carved until I set him free” – Michelangelo</p> */}
        <button className="primary-btnrecedential" onClick={handleKnowMoreClick}>Know More</button>
      </div>
      <div className="recedentialright-section">
        <img src="./images/ourwork/ourwork-1.png" alt="Residential Project" />
      </div>
    </div>
  );
};

export default Recedential;
