import React, { useState, useEffect } from "react";
import axios from "axios";
import "./EnquiryForm.css";
import { useNavigate } from "react-router-dom";

const EnquiryForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    contactNo: "",
    message: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formOpen, setFormOpen] = useState(true); // State variable to track whether the form is open
  const navigate = useNavigate();

  // Disable scrolling when the form is open
  useEffect(() => {
    if (formOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto"; // Re-enable scrolling when the component unmounts
    };
  }, [formOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        "https://sarchi-backend.vercel.app/api/submit-enquiry-form",
        formData
      );
      setFormSubmitted(true);
      setTimeout(() => {
        navigate("/thank-you");
      }, 3000);
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error
    }
  };

  const handleCancel = () => {
    setFormData({
      firstName: "",
      email: "",
      contactNo: "",
      message: "",
    });
    setFormSubmitted(false);
    setFormOpen(false); // Close the form
  };

  if (!formOpen) {
    return null;
  }

  return (
    <div>
      <div className="background-overlay" onClick={handleCancel}></div>
      <div className={`form-page-content ${formSubmitted ? "submitted" : ""}`}>
        <div className="cancel-button" onClick={handleCancel}>
          <span>&times;</span>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-element">
            <label htmlFor="firstName">Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              placeholder="Enter your name"
              value={formData.firstName}
              onChange={(e) =>
                setFormData({ ...formData, firstName: e.target.value })
              }
              required
            />
          </div>
          <div className="form-element">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              required
            />
          </div>
          <div className="form-element">
            <label htmlFor="contactNo">Contact No</label>
            <input
              type="text"
              id="contactNo"
              name="contactNo"
              placeholder="Enter your contact number"
              value={formData.contactNo}
              onChange={(e) =>
                setFormData({ ...formData, contactNo: e.target.value })
              }
              required
            />
          </div>
          <div className="form-element">
            <label htmlFor="message">How can we help you?</label>
            <textarea
              id="message"
              name="message"
              placeholder="Enter your message"
              rows="3"
              value={formData.message}
              onChange={(e) =>
                setFormData({ ...formData, message: e.target.value })
              }
            ></textarea>
          </div>
          <div className="equiry-form">
            <button type="submit">Submit</button>
          </div>
        </form>
        {formSubmitted && (
          <div className="form-submitted-indicator">
            <i className="fa fa-check-circle"></i>
            <p>Thanks for reaching us!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EnquiryForm;
