import React from 'react';
import './Welcometo.css';

const Welcometo = () => {
  return (
    <div className='welcometo-container'>
      <div className='welcometo-left'>
        <span className="headingtitle">Welcome to Sarchi Group of Architects</span>
        <div className="text-content">
          <div className='title'>“Create a livable space with the help of geometry is an art”</div>
        </div>
        <div className="divider"></div>
        <div className="para-content">
          <p>Sarchi Group of Architects is multifaceted in architectural interior spaces. We create spaces with best suitable functions , best suitable aesthetics , best context response design and unique touch of client personality.
We are engage in residential , commercial  & hospitality spaces  . We are superior in creating art with extraordinary solutions.</p>
        </div>
        <a href="/about-us/" className="primary-btn-custom"><span>Know More  </span></a>
      </div>
      <div className="image-content">
          <img
            width="663"
            height="800"
            src="./images/welcometo.png"
            className="full-attachment"
            alt=""
            loading="lazy"
          />
        </div>
      </div>
  );
};

export default Welcometo;
