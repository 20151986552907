import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import './Weoperate.css';

const WeOperate = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  // Function to handle button click and navigate
  const handleNavigation = (path) => {
    navigate(path); // Use navigate to navigate to the specified path
  };

  return (
    <div className="we-operate-container">
      <div className="columns">
        <div className='columns1'><h2>We operate in the following categories</h2></div>
      </div>
      <div className="columns">
        <button className='button' onClick={() => handleNavigation('/services/architectural-design')}>
          Architectural Designs
        </button>
      </div>
      <div className="columns">
        <button className='button' onClick={() => handleNavigation('/services/interior-design')}>
          Interior Designs
        </button>
      </div>
      <div className="columns">
        <button className='button' onClick={() => handleNavigation('/services/landscape-design')}>
          Landscape Designs
        </button>
      </div>
      <div className="columns">
        <button className='button' onClick={() => handleNavigation('/services/turnkey-project')}>
          Turnkey Projects
        </button>
      </div>
    </div>
  );
};

export default WeOperate;
