// Weturn.js
import React from 'react';
import './Weturn.css';
import wedesign from './wedesign.png';
import westyle from './westyle.jpg'
import wexecute from './weexecute.jpg'

const Weturn = () => {
  return (
    <div className="weturn-container">
      <div className="weturn-title">
        <h1>We turn spaces into art, imagination into design, and your memories into stories.</h1>
        <div className="custom-divider-weturn"></div>

      </div>

     

      <div className="weturn-boxes">
        
        <div className="weturn-box">
          <div className="weturn-box-inner">
            <div className="weturn-box-front">
              <img src={wedesign} alt="Design Image" />
            </div>
            <div className="weturn-box-back">
              <h3>We Design</h3>
              <p>We apply creative, aesthetically attractive and technical solutions within a structure to achieve the desired environment.</p>
            </div>
          </div>
        </div>
        <div className="weturn-box pb">
          <div className="weturn-box-inner">
            <div className="weturn-box-front">
              <img src={wexecute} alt="Design Image" />
            </div>
            <div className="weturn-box-back">
              <h3>We Execute</h3>
              <p>Precision, punctuality and professionalism allow us to transform designs on paper into real-world wonders. Experiencing our team at work is like poetry in motion.</p>
            </div>
          </div>
        </div>
        <div className="weturn-box pb">
          <div className="weturn-box-inner">
            <div className="weturn-box-front">
              <img src={westyle} alt="Design Image" />
            </div>
            <div className="weturn-box-back">
              <h3>We Style</h3>
              <p>Are you looking for re-fashioning your space for a special occasion? We have outstanding ideas and customized solutions to refresh and make your space beam like never before.</p>
            </div>
          </div>
        </div>

       
      </div>
    </div>
  );
};

export default Weturn;
