import React, { useState } from "react";
import "./ProductiveTalk.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ProductiveTalk = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    contactNo: "",
    interest: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://sarchi-backend.vercel.app/api/submit-user-form",
        formData
      );
      if (response.status === 201) {
        setFormSubmitted(true);
        setTimeout(() => {
          navigate("/thank-you");
        }, 3000);
      } else {
        console.error("Failed to submit form:", response.statusText);
      }
    } catch (err) {
      console.error("Error submitting form:", err);
    }
  };

  return (
    <div className="productive-talk-container">
      <div className="sub-container">
        <div className="productivetalkleft-box">
          <div className="whole-form">
            <div className="productivetalktitle">
              Let&apos;s have a productive talk.
            </div>
            <div className="customes-divider"></div>
            <div className="form-box">
              {!formSubmitted ? (
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="Name:"
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email:"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  <input
                    type="text"
                    id="contactNo"
                    name="contactNo"
                    placeholder="Contact Us:"
                    value={formData.contactNo}
                    onChange={handleInputChange}
                  />
                  <textarea
                    id="interest"
                    name="interest"
                    placeholder="I am interested in:"
                    rows="4"
                    style={{ width: "100%", padding: "10px" }}
                    value={formData.interest}
                    onChange={handleInputChange}
                  ></textarea>
                  <button type="submit" className="primary-btn">
                    <span>Send</span>
                  </button>
                </form>
              ) : (
                <div className="form-submitted-indicator">
                  <i className="fa fa-check-circle"></i>
                  <p>Thank you for contacting us!</p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Right Box */}
        <div className="productivetalkright-box">
          <div className="productivetalkrighttitle">
            Best Interior Designer In Pune
          </div>
          <p className="productivetalk-para">
            Sarchi Group of Architects, based in Pune, is multifaceted in
            architectural interior spaces. We specialize in creating spaces with
            the best-suited functions, aesthetics, context-responsive design,
            and a unique touch of client personality.
          </p>
          <p className="productivetalk-para">
            We are engaged in residential, commercial, and hospitality spaces.
            Our expertise extends to crafting artful solutions that elevate the
            essence of each space.
          </p>
          <p className="productivetalk-para">
            For those seeking excellence in residential or commercial design in
            Pune, Sarchi Group of Architects is the ultimate choice. We are
            committed to shaping spaces and curating legacies through our
            distinctive approach to interior design.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductiveTalk;
