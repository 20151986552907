import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import HospitalityGems from './pages/Home/OurPortfolio/HospitalityGems/HospitalityGems';
// import CommercialWonders from './pages/Home/OurPortfolio/CommercialWonders/CommercialWonders';
import Footer from './components/Footer/Footer';
import AboutUs from './pages/AboutUs/AboutUs';
import Career from './pages/Career/Career';
import Ourwork from './pages/Ourwork/Ourwork';
import Team from './pages/Team/Team';
import ContactUs from './pages/ContactUs/ContactUs';
import Services from './pages/Services/Services';
import ArchitecturalDesign from './pages/Services/ArchitecturalDesignPage/ArchitecturalDesignPage';
import InteriotDesignPage from './pages/Services/InteriorDesignPage/InteriorDesignPage';
import LandScapeDesignPage from './pages/Services/LandscapeDesignPage/LandscapeDesignPage';
import TurnkeyProjectPage from './pages/Services/TurnkeyProjectPage/TurnkeyProjectPage';
import EnquiryForm from './components/StickyEnquiryButton/EnquiryForm/EnquiryForm';
import Thankyou from './components/Thankyou/Thankyou';
import ScrollToTopButton from './components/ScrollToTopButton/ScrollToTopButton';
// import StickyHomeButton from './components/StickyHomeButton/StickyHomeButton';

// import LazyLoadedComponent from './components/LazyLoadedComponent';
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <Router>
    {/* <StickyHomeButton/> */}
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/HospitalityGems" element={<HospitalityGems />} />
        {/* <Route path="/CommercialWonders" element={<CommercialWonders />} /> */}
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/architectural-design" element={<ArchitecturalDesign />} />
        <Route path="/services/interior-design" element={<InteriotDesignPage />} />
        <Route path="/services/Landscape-design" element={<LandScapeDesignPage />} />
        <Route path="/services/turnkey-project" element={<TurnkeyProjectPage />} />
        <Route path="/career" element={<Career />} />
        <Route path="/our-work" element={<Ourwork />} />
        <Route path="/team" element={<Team />} />
        <Route path="/Contact-us" element={<ContactUs />} />
        <Route path="/" element={<EnquiryForm />} />
        <Route path="/thank-you" element={<Thankyou />} />
        
        

      </Routes>
      <Footer />
      <ScrollToTopButton/>
    </Router>
  );
};

export default App;