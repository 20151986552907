// LandscapeDesignPage.js

import React from 'react';
import './LandscapeDesignPage.css';

const LandscapeDesignPage = () => {
  return (
    <div className="landscape-design-page">
      <div className="center-content">
        <h1 className="coming-soon">Coming Soon 🏡</h1>
      </div>
    </div>
  );
}

export default LandscapeDesignPage;
