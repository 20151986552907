import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import './Commertial.css';

const Commertial = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleKnowMoreClick = () => {
    navigate('/services/interior-design'); // Navigate to the specified URL
  };

  return (
    <div className="commertial-container">
      <div className="commertialright-section">
        <img src="./images/ourwork/ourwork-2.png" alt="Commercial Project" />
      </div>
      <div className="commertialleft-section">
        <h2>Interior Design  Projects</h2>
        <div className="custom-divider"></div>
        {/* <p>“I saw the angel in the marble and carved until I set him free” – Michelangelo</p> */}
        <button className="primary-btnrecedential" onClick={handleKnowMoreClick}>Know More</button>
      </div>
    </div>
  );
};

export default Commertial;
