import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaChevronLeft, FaChevronRight, FaTimes } from 'react-icons/fa'; // Import icons for prev, next, and close buttons
import './ProjectModal.css'; // Import CSS file for styling the modal

const ProjectModal = ({ images, onClose }) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handlePrev = (e) => {
    e.stopPropagation(); // Prevent event propagation to parent elements
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1)); // Update currentIndex conditionally
  };

  const handleNext = (e) => {
    e.stopPropagation(); // Prevent event propagation to parent elements
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? prevIndex : prevIndex + 1));
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft') {
        handlePrev(e);
      } else if (e.key === 'ArrowRight') {
        handleNext(e);
      }
    };

    // Disable scrolling when the modal is open
    document.body.classList.add('modal-open');

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      // Enable scrolling when the modal is closed
      document.body.classList.remove('modal-open');
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentIndex, handlePrev, handleNext]);

  return (
    <div className="project-modal-overlay" onClick={onClose}>
      <div className="project-modal" onClick={(e) => e.stopPropagation()}>
        {currentIndex !== 0 && (
          <button className="prev-button" onClick={handlePrev}><FaChevronLeft /></button>
        )}
        <div className="carousel">
          <img src={images[currentIndex].src} alt={images[currentIndex].alt} />
        </div>
        {currentIndex !== images.length - 1 && (
          <button className="next-button" onClick={handleNext}><FaChevronRight /></button>
        )}
        <button className="close-button" onClick={onClose}><FaTimes /></button>
      </div>
    </div>
  );
};

ProjectModal.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  })).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ProjectModal;
