// projectsData.js
const projectsData = {
  architectural_design: {
    "project-1": [
      { "src": "/images/Architecture/P1/3.png", "alt": "Project 1 Image 1" },
      { "src": "/images/Architecture/P1/4.png", "alt": "Project 1 Image 2" },
      { "src": "/images/Architecture/P1/1.jpg", "alt": "Project 1 Image 3" },
      { "src": "/images/Architecture/P1/2.jpg", "alt": "Project 1 Image 4" },
      { "src": "/images/Architecture/P1/5.jpg", "alt": "Project 1 Image 5" },
      { "src": "/images/Architecture/P1/6.jpg", "alt": "Project 1 Image 6" },
      { "src": "/images/Architecture/P1/7.jpg", "alt": "Project 1 Image 7" }
    ],
    "project-2": [
      { "src": "/images/Architecture/P2/1.png", "alt": "Project 2 Image 1" }
    ],
    "project-3": [
      { "src": "/images/Architecture/P3/1.png", "alt": "Project 3 Image 1" },
      { "src": "/images/Architecture/P3/2.png", "alt": "Project 3 Image 2" }
    ],
    "project-4": [
      { "src": "/images/Architecture/P4/1.png", "alt": "Project 4 Image 1" }
    ],
    "project-5": [
      { "src": "/images/Architecture/P5/1.jpg", "alt": "Project 5 Image 1" },
      { "src": "/images/Architecture/P5/2.jpg", "alt": "Project 5 Image 2" }
    ],
    "project-6": [
      { "src": "/images/Architecture/P6/1.png", "alt": "Project 6 Image 1" },
      { "src": "/images/Architecture/P6/2.png", "alt": "Project 6 Image 2" }
    ],
    "project-7": [
      { "src": "/images/Architecture/P7/1.png", "alt": "Project 7 Image 1" }
    ],
    "project-8": [
      { "src": "/images/Architecture/P9/1.png", "alt": "Project 8 Image 1" }
    ],
    "project-9": [
      { "src": "/images/Architecture/P10/1.jpg", "alt": "Project 9 Image 1" }
    ]
  }
};

export default projectsData;
