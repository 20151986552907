// StickyIcons.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons'; // Change import statement here
import './StickyIcons.css';

const StickyIcons = () => {
  return (
    <div className="sticky-icons-container">
      <div className="call-icon">
      <a href="tel:+917499749762"><FontAwesomeIcon icon={faPhone} /></a>
      </div>
      <div className="whatsapp-icon">
      <a href="https://api.whatsapp.com/send/?phone=%2B917499749762&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faWhatsapp} /></a>
      </div>
    
    </div>
  );
};

export default StickyIcons;
