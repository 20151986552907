import React from 'react';
import './Asinterior.css';

const AsInterior = () => {
    return (
        <div className="asinterior-container">
            <div className="asinteriorleft-side">
                <div className='left-title'>Transforming Spaces into Art, Imagination into Design, and Your Memories into Elegance</div>
                <h5>DISTINCT VIEWPOINT</h5>
            </div>
            <div className="asinteriorright-side">
                <div className="asinteriorright-side-content">
                    <p className='asinterior-para'>We are visionary designers, adept in both architecture and interior realms, committed to bringing your aspirations to life. Drawing inspiration from Sarchi Group of Architects, our team excels in crafting sophisticated living spaces and architectural designs. From layout and construction to intricate details, we create vibrant realities that mirror your unique lifestyle.</p>
                    </div>
        <a href="/about-us/" className="primary-btnknowmore"><span>Know More  </span></a>
      </div>
        </div>
    );
};

export default AsInterior;
