import React from "react";
import "./Callus.css";

const Callus = () => {
  return (
    <div className="callus-container">
      <div className="box">
        <i className="fas fa-phone-alt icon"></i>
        <h4>
          <a href="tel:+917499749762">+91 7499749762</a>
        </h4>
        {/* <h4><a href="tel:+918999926783">+91 8999926783</a></h4> */}
      </div>

      <div className="box boxno-2">
        <i className="far fa-envelope icon"></i>
        <h4>
          <a href="mailto:sarchigarchitects@gmail.com">
            sarchigarchitects@gmail.com
          </a>
        </h4>
      </div>

      <div className="box  boxno-3">
        <i className="fas fa-home icon"></i>
        {/*<h4>Office:</h4>*/}
        {/* <p className='callus-para'><b>Pune office:</b> Flat No. 402, Mayaca Enclave, Punawale, Pimpri-Chinchwad, Maharashtra 411033.</p> */}
        <p className="callus-para">
          <b>Baramati office:</b> Rajashri shahu Maharaj complex ,Gunawdi chowk,
          Baramati, Maharashtra 413102
        </p>
      </div>
    </div>
  );
};

export default Callus;
