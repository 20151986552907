import React, { useState, useEffect } from 'react';
import './Carousel3D.css';
import image1 from './1.png'; // Importing individual images
import image2 from './2.png';
import image3 from './3.png';
import image4 from './4.png';
import image5 from './5.png';

const imagesData = [
  {
    id: 1,
    src: image1,
    alt: 'Slide 1'
  },
  {
    id: 2,
    src: image2,
    alt: 'Slide 2'
  },
  {
    id: 3,
    src: image3,
    alt: 'Slide 3'
  },
  {
    id: 4,
    src: image4,
    alt: 'Slide 4'
  },
  {
    id: 5,
    src: image5,
    alt: 'Slide 5'
  }
];

const Carousel3D = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Calculate the index of the next slide
      const nextSlideIndex = (currentSlide + 1) % imagesData.length;
      setCurrentSlide(nextSlideIndex);
    }, 3000); // Change slide every 5 seconds

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [currentSlide]);

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  return (
    <section id="slider">
      {imagesData.map((image, index) => (
        <input
          key={image.id}
          type="radio"
          name="slider"
          id={`s${index + 1}`}
          checked={index === currentSlide}
          onChange={() => handleSlideChange(index)}
        />
      ))}
      {imagesData.map((image, index) => (
        <label key={image.id} htmlFor={`s${index + 1}`} id={`slide${index + 1}`}>
          <img
            src={image.src}
            alt={image.alt}
          />
        </label>
      ))}
    </section>
  );
};

export default Carousel3D;
