import React, { useState } from "react";
import "./Map.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Map = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    contactNo: "",
    interest: "",
  });
  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://sarchi-backend.vercel.app/api/submit-user-form",
        formData
      );
      if (response.status === 201) {
        setFormSubmitted(true);
        setTimeout(() => {
          navigate("/thank-you"); // Redirect to thank-you page
        }, 3000);
        console.log("Form submitted:", formData);
      } else {
        console.error("Failed to submit form:", response.statusText);
      }
    } catch (err) {
      console.error("Error submitting form:", err);
      setErrors(err.response.data.errors); // Set errors received from the server
    }
  };

  return (
    <div className="map-container">
      <div className="left-sectionmap">
        <div className="createyourstory-left">
          <h2>Let’s create your Story</h2>
          <p className="create-your-para">
            Sarchi Group of Architectis multifaceted in architectural interior
            spaces. We create spaces with best suitable functions , best
            suitable aesthetics , best context response design and unique touch
            of client personality.
          </p>
        </div>
        <div className="form-box">
          {!formSubmitted ? (
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder="Name"
                value={formData.firstName}
                onChange={handleInputChange}
              />
              {errors.firstName && (
                <div className="error">{errors.firstName}</div>
              )}
              <div className="input-with-icon">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {errors.email && <div className="error">{errors.email}</div>}
              </div>
              <input
                type="text"
                id="contactNo"
                name="contactNo"
                placeholder="Contact Us"
                value={formData.contactNo}
                onChange={handleInputChange}
              />
              {errors.contactNo && (
                <div className="error">{errors.contactNo}</div>
              )}
              <textarea
                id="interest"
                name="interest"
                placeholder="I am interested in"
                rows="4"
                value={formData.interest}
                onChange={handleInputChange}
              ></textarea>
              {errors.interest && (
                <div className="error">{errors.interest}</div>
              )}
              <button type="submit" className="primary-btn">
                <span>Send</span>
              </button>
            </form>
          ) : (
            <div className="thank-you-message">
              <i className="fa fa-check-circle"></i>
              <p>Thank you for contacting us!</p>
            </div>
          )}
        </div>
      </div>
      <div className="right-sectionmap">
        <img
          width="735"
          height="690"
          src="https://technocred.com/wp-content/uploads/2019/07/contact-girl1.gif"
          className="attachment-medium_large size-medium_large"
          alt=""
          decoding="async"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default Map;
