import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './InteriorDesignPage.css';
import ProjectModal from './ProjectModal'; // Import the ProjectModal component
import projectsData from './projectsData'; // Import the projectsData array

const InteriorDesignPage = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleClick = (projectId) => {
    setSelectedProject(projectId);
  };

  const handleCloseModal = () => {
    setSelectedProject(null);
  };

  // Function to navigate to the architectural design page
  const navigateToArchitecturalDesign = () => {
    navigate('/services/architectural-design');
  };

  return (
    <div className="interior-design-page">
      <div className="sidebar">
        <ul>
          <li className="active">Interior Design</li>
          {/* Wrap the button inside the <li> element */}
          <li onClick={navigateToArchitecturalDesign}>
            <button>Architectural Design</button>
          </li>
        </ul>
      </div>
      <div className={`content ${selectedProject ? 'blur' : ''}`}>
        <div className="projects-grid">
          {projectsData.map(project => (
            <div className="project" key={project.id} onClick={() => handleClick(project.id)}>
              <div className="project-overlay">
                <p>Click to see {project.title} images</p>
              </div>
              <img src={project.imageUrl} alt={project.title} />
            </div>
          ))}
        </div>
      </div>
      {selectedProject && (
        <ProjectModal 
          images={projectsData[selectedProject - 1].images}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default InteriorDesignPage;
