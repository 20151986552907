import React from 'react';
import './Homepage.style.css';

import WelcomeTo from '../../components/Home/Welcometo/Welcometo';
import WeOperate from '../../components/Home/Weoperate/Weoperate';
import AsInterior from '../../components/Home/Asinterior/Asinterior';
import Carousel from '../../components/Home/Carousel/Carousel';
// import Clientsay from '../../components/Home/Clientsay/Slider';
import ProductiveTalk from '../../components/Home/ProductiveTalk/ProductiveTalk';
// import StickyEnquiryButton from '../../components/StickyEnquiryButton/StickyEnquiryButton';
import StickyIcons from '../../components/StickyIcons/StickyIcons';
import ScrollToTopButton from '../../components/ScrollToTopButton/ScrollToTopButton';


const Home = () => {
  
  return (
    <div>
   
     <ScrollToTopButton/> 
      <StickyIcons />
      {/* <StickyEnquiryButton /> */}
      <Carousel/>
      <WelcomeTo/>
      <WeOperate/>
      <AsInterior/>
      {/* <Portfolio/> */}
      {/* <NewsWriting/> */}
      {/* <Clientsay/> */}
      {/* <Slider/> */}
      <ProductiveTalk/>
      
      
    </div>
  );
};

export default Home;











