import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Importing useLocation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'; // Don't forget to import faBars
import sarchilogo from './sarchi-log.png'
import './Navbar.css';

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const location = useLocation(); // Using useLocation to access the current URL path

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    document.body.classList.toggle('menu-open', !isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
    document.body.classList.remove('menu-open');
  };

  return (
    <nav className={`navbar navbar-expand-lg fixed-top homepage-navbar ${scrolled ? 'scrolled' : ''} ${isMenuOpen ? 'active' : ''} p-0`}>
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
      <img
        src={sarchilogo} // Use the imported image variable
        alt="Logo"
        width="100"
        height="120"
        className="d-inline-block align-top"
      />
    </a>
        <button
          className={`navbar-toggler ${isMenuOpen ? 'active' : ''}`}
          type="button"
          onClick={toggleMenu}
        >
          {isMenuOpen ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
        </button>
        <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>

          <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
            <li className="nav-item" id='li-1'>
              <Link
                to="/"
                className="nav-link"
                onClick={closeMenu}
                style={{
                  color: location.pathname === '/' ? '#5d2303' : 'black',
                  fontSize: location.pathname === '/' ? '1em' : 'inherit',
                  backgroundColor: location.pathname === '/' ? '' : 'transparent',
                  borderRadius: location.pathname === '/' ? '25%' : '0'
                }}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about-us" className="nav-link" onClick={closeMenu}
 style={{
            color: location.pathname === '/about-us' ? '#5d2303' : 'black',
            fontSize: location.pathname === '/about-us' ? '1em' : 'inherit',
            backgroundColor: location.pathname === '/about-us' ? '#eeeee4' : 'transparent',
            borderRadius: location.pathname === '/about-us' ? '5%' : '0'
          }}               >
                About Us
              </Link>
            </li>
            <li className="nav-item">
  <Link to="/services" className="nav-link" onClick={closeMenu} style={{color: location.pathname === '/services' ? '#5d2303' : 'black', fontSize: location.pathname === '/services' ? '1em' : 'inherit', backgroundColor: location.pathname === '/services' ? '#eeeee4' : 'transparent', borderRadius: location.pathname === '/services' ? '5%' : '0'}}>
    Services
  </Link>
</li>
<li className="nav-item">
  <Link to="/services/interior-design" className="nav-link" onClick={closeMenu} style={{color: location.pathname === '/services/interior-design' ? '#5d2303' : 'black', fontSize: location.pathname === '/services/interior-design' ? '1em' : 'inherit', backgroundColor: location.pathname === '/services/interior-design' ? '#eeeee4' : 'transparent', borderRadius: location.pathname === '/services/interior-design' ? '5%' : '0'}}>
    Our Work
  </Link>
</li>
<li className="nav-item">
  <Link to="/career" className="nav-link" onClick={closeMenu} style={{color: location.pathname === '/career' ? '#5d2303' : 'black', fontSize: location.pathname === '/career' ? '1em' : 'inherit', backgroundColor: location.pathname === '/career' ? '#eeeee4' : 'transparent', borderRadius: location.pathname === '/career' ? '5%' : '0'}}>
    Career
  </Link>
</li>
<li className="nav-item" id='li-6'>
  <Link to="/contact-us" className="nav-link" onClick={closeMenu} style={{color: location.pathname === '/contact-us' ? '#5d2303' : 'black', fontSize: location.pathname === '/contact-us' ? '1em' : 'inherit', backgroundColor: location.pathname === '/contact-us' ? '#eeeee4' : 'transparent', borderRadius: location.pathname === '/contact-us' ? '5%' : '0'}}>
    Contact Us
  </Link>
</li>

           
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
