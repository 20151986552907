import React from 'react';
import PropTypes from 'prop-types';

const PagePath = ({ path }) => {
  return (
    <div className="page-path">
      <a href="/home">
        <h5>Sarchi group of Architects</h5>
      </a>
      <span className="separator"></span> {path}
      <style >{`
        .page-path {
          display: flex;
          align-items: center;
          background-color: white;
          color: black;
          font-size: 13px;
          margin-left: 80px;
          padding: 5px;
          font-family: 'Acta Display W01 Medium';
        }

        .page-path h5 {
          color: #999999;
          margin-right: 10px; /* Adjust margin as needed */
          font-size: 13px;
          margin-top: 10px;
          font-family: 'Acta Display W01 Medium';
        }
        .page-path a {
          text-decoration: none;
        }

        .separator::before {
          content: '>';
          margin: 0 10px; /* Adjust spacing as needed */
          color: #333; /* Color for the separator */
        }
      `}</style>
    </div>
  );
};

PagePath.propTypes = {
  path: PropTypes.string.isRequired,
};

export default PagePath;
