// YourSpace.js

import React from 'react';
import './Yourspace.css'; // Import the styles

const YourSpace = () => {
  return (
    <div className="container-yourspace">
      <div className="rightSection">
        <div className="rightTop">
          {/* <p>
               Welcome to Sarchi Group of Architects, the premier provider of unparalleled interior solutions for residential, commercial and corporate clients. Renowned for delivering the best interiors, we have established ourselves as the ultimate destination for individuals and businesses seeking exceptional craftsmanship and timeless elegance.
          </p> */}
          <p>
          Sarchi Group of Architects is a renowned global firm that brings together a team of architects, designers, strategists, and specialists dedicated to the field of interior architecture. Our unwavering focus on creating transformative environments sets us apart as agents of change, driven by creativity, innovation, and sustainable growth while actively contributing to the betterment of our communities.
          </p>
          <p>
            Our eye for meticulous details helps us to understand client needs,
            formulate seamless design strategies and fashion flawless spaces for
            those who matter the most to us – you.
          </p>
          <p>
          At Sarchi Group of Architects, we take great pride in our in-house design, production, and execution teams, which operate in perfect harmony to bring extraordinary interior spaces to life. With our state-of-the-art manufacturing facility, we possess the capabilities to transform the most ambitious design concepts into stunning realities, setting new benchmarks for interior excellence. Dedicated to our craft and driven by an unwavering commitment to detail, we have earned the trust and admiration of countless clients throughout the nation. Our unmatched expertise and unwavering commitment to quality have positioned us as the go-to choice for those who seek the pinnacle of interior design. 
          </p>
          <div className="yourspace-images">
                <div className="img-1" data-aos="fade-right" data-aos-duration="2000">
                    <img src="./images/aboutus/aboutus1.png" alt="" className="img-fluid-aboutus abtimg1 flash"/> 
                </div>
                <div className="img-2" data-aos="fade-left" data-aos-duration="2000">
                    <img src="./images/aboutus/aboutus02.png" alt="" className="img-fluid-aboutus abtimg2 flash" />
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default YourSpace;
