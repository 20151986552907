import React, { useState } from 'react';
import './HospitalityGems.css'; // Import the CSS file
import HorizontalLine from './HorizontalLine';
import PagePath from '../../../../components/PagePath';

function HospitalityGems() {
    const Hospitality = () => {
        const gridItems = [
          {
            image: './images/hospitality1.png',
            area: 'Luxury Suite',
            residenceName: 'Grand Hotel, Paris',
          },
          // Add items for the other hospitality blocks
          {
            image: './images/hospitality2.png',
            area: 'Restaurant',
            residenceName: 'Seaside Resort, Bahamas',
          },
          {
            image: './images/hospitality3.png',
            area: 'Spa & Wellness Center',
            residenceName: 'Mountain Lodge, Switzerland',
          },
          {
            image: './images/hospitality4.png',
            area: 'Outdoor Pool',
            residenceName: 'Tropical Resort, Maldives',
          },
          {
            image: './images/hospitality5.png',
            area: 'Beachfront Villa',
            residenceName: 'Luxury Resort, Bali',
          },
          // Add more items here
        ];
      
        const [showTextState, setShowTextState] = useState({});
      
        const handleHover = (itemIndex) => {
          setShowTextState((prevState) => ({
            ...prevState,
            [itemIndex]: !prevState[itemIndex],
          }));
        };

        return (
          <div className="residential-container">
           <HorizontalLine />
                <PagePath path="Recedential Marverls" /> 
            <div className="grid-container">
              {gridItems.map((item, index) => (
                <div
                  key={index}
                  className="grid-item"
                  onMouseEnter={() => handleHover(index)}
                  onMouseLeave={() => handleHover(index)}
                >
                  <img
                    src={item.image}
                    alt={item.area}
                    className="grid-item-image" // Add a class for zooming
                  />
                  <div className="grid-item-text">
                    {showTextState[index] && (
                      <>
                        <h3>{item.area}</h3>
                        <p>{item.residenceName}</p>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
            </div>
          );
    };
    
    return <Hospitality />;
}

export default HospitalityGems;
