import React from 'react';
import {  useNavigate } from 'react-router-dom';
import './Servicescomponent.css'; 
import servicesData from './servicesData';

const ServiceComponent = () => {
  const navigate = useNavigate();

  return (
    <div className='service-component-heading'>
      <div className="header-container">
        <h2 className="sub-heading">We design spaces that help build a better tomorrow</h2>
      </div>

      {servicesData.map((service, index) => (
        <div className="service-row custom-border" key={index}>
          <div className="service-column number-column">
            <h5>{service.number}</h5>
          </div>
          <div className="service-column title-column">
            <div className='service-title'>{service.title}</div>
          </div>
          <div className="service-column logo-column">
            <img
              src={service.logoSrc} 
              alt={`Service Icon for ${service.title}`}
              className="img-fluid flash"
            />
          </div>
          <div className="service-column description-column">
            <p className='service-paragraph'>{service.description}</p>
          </div>
          <div className="service-column read-more-column">
            <p className="learn-more-text">
              <button className="read-more-button" onClick={() => navigate(`/services/${service.title.replace(/\s+/g, '-').toLowerCase().replace(/^[-]+/, '')}`)}>
                Read More <i className="fa-solid fa-arrow-right-long"></i>
              </button>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServiceComponent;
